import { Tutorial } from "src/features/easter-eggs/types";
import { LEVEL1_DIALOGUES } from "./level1";
import { IDLE_DIALOGUE } from "src/features/easter-eggs/idle";

export const getDialogByLevel = (tutorial?: Tutorial) => {
    if (!tutorial) return LEVEL1_DIALOGUES;

    // every other level is a hidden level (hidden requirements in order to pass)
    // odd levels have a specific tutorial
    if (tutorial.level > 0 && tutorial.level % 2 === 0) {
        return IDLE_DIALOGUE;
    }

    switch (tutorial.level) {
        case 0:
        case 1:
            return LEVEL1_DIALOGUES;
        default:
            return IDLE_DIALOGUE;
    }
};
