import useActive from './useActive'
import { Event, Task, isDBTaskId } from 'src/types'
import { useGlobalPanda } from '@/components/specific/toolbar/components/useShowPanda'
import { useGetUserQuery, useUpdatePlannerItemMutation } from '@/redux/features/api/apiSlice'
import { useMemo } from 'react'
import { groupBy } from 'lodash'
import { addWeeks, format, isToday, startOfWeek } from 'date-fns'
import { DayOfWeek } from '@/features/calendars/types'
import { MS_PER_MINUTE } from '@/constants/date'
import { formatDurationSimplified } from '@planda/utils'

export interface TaskEventInfo {
    minutesCompleted: number
    scheduledUpcomingMinutes: number
    upcomingEvents: Event[]
    completedReadable: string
    upcomingReadable: string
}

export default function useTasks(path?: string) {
    const props = useActive('task', path)
    const [updateItem] = useUpdatePlannerItemMutation({ fixedCacheKey: 'planner-list' })
    const { chanceShowPanda, showPanda } = useGlobalPanda()
    const items = props.items as Task[] | undefined
    const { data: user } = useGetUserQuery()

    const taskEventsDict = useMemo(() => {
        const events = props.categoryRes?.items.event || []
        const dict = groupBy(
            events.filter((x) => x.parentId && isDBTaskId(x.parentId)),
            (x) => x.parentId
        )
        const splitDict: Record<string, TaskEventInfo> = {}
        const dateNow = Date.now()
        Object.entries(dict).forEach(([key, value]) => {
            const formatMinutes = (minutes: number) => {
                return formatDurationSimplified({ minutes: Math.round(minutes) })
            }
            const minutesCompleted =
                value.reduce((acc, x) => {
                    return acc + Math.max(Math.min(dateNow, x.dateEnd) - x.dateStart, 0)
                }, 0) / MS_PER_MINUTE
            const scheduledUpcomingMinutes =
                value.reduce((acc, x) => {
                    return acc + Math.max(x.dateEnd - Math.max(dateNow, x.dateStart), 0)
                }, 0) / MS_PER_MINUTE
            splitDict[key] = {
                minutesCompleted,
                scheduledUpcomingMinutes,
                completedReadable: formatMinutes(minutesCompleted),
                upcomingReadable: formatMinutes(scheduledUpcomingMinutes),
                upcomingEvents: value.filter((x) => x.dateEnd > Date.now()),
            }
        })
        return splitDict
    }, [props.categoryRes])

    const nextWorkingOn = (id: string) => {
        const nextEvent = taskEventsDict[id]?.upcomingEvents[0]
        if (!nextEvent) return
        if (isToday(nextEvent.dateStart))
            return {
                dateString: 'Today',
                dateCategory: 'today' as const,
            }
        const nextWeekStart = addWeeks(startOfWeek(Date.now(), { weekStartsOn: user?.weekStartsOn as DayOfWeek }), 1).getTime()

        if (nextEvent.dateStart < nextWeekStart)
            return {
                dateString: format(nextEvent.dateStart, 'EEE'),
                dateCategory: 'week' as const,
            }
        return {
            dateString: format(nextEvent.dateStart, 'MMM d'),
            dateCategory: 'future' as const,
        }
    }

    const toggleCompleted = (id: string, checked: boolean) => {
        const item = items?.find((x) => x.id === id)
        const numSubtasks = item?.subtasks?.length || 0
        const priority = item?.priority || 0
        if (numSubtasks > 2 || priority > 1) showPanda()
        else chanceShowPanda()
        props.edit(id, { completed: checked ? Date.now() : 0 })
    }

    const toggleSubtaskCompleted = async (id: string, index: number, checked: boolean) => {
        // const subtasks = [...(items?.find(x => x.id === id)?.subtasks || [])]
        // if (subtasks.length === 0) return

        const completed = checked ? Date.now() : 0
        // const promise = fetchPatchItem(id, { set: { [`subtasks[${index}].completed`]: completed } })
        // await props.edit(id, { subtasks }, promise)
        return updateItem({ id, updates: { set: { [`subtasks[${index}].completed`]: completed } } })
    }

    const removeSubtask = async (id: string, index: number) => {
        return updateItem({ id, updates: { remove: [`subtasks[${index}]`] } })
    }

    const renameSubtask = async (id: string, index: number, name: string) => {
        // const subtasks = [...(items?.find(x => x.id === id)?.subtasks || [])]
        // subtasks[index].name = name

        // const promise = fetchPatchItem(id, { set: { [`subtasks[${index}].name`]: name } })
        // await props.edit(id, { subtasks }, promise)
        return updateItem({ id, updates: { set: { [`subtasks[${index}].name`]: name } } })
    }

    const addSubtask = async (id: string, name: string) => {
        props.append(id, 'subtasks', [{ name, completed: 0 }])
    }

    const changeDifficulty = async (id: string, difficulty: number) => {
        return updateItem({ id, updates: { set: { difficulty } } })
    }

    return {
        ...props,
        items,
        subtaskHelpers: {
            toggleSubtaskCompleted,
            removeSubtask,
            renameSubtask,
            addSubtask,
        },
        nextWorkingOn,
        toggleCompleted,
        taskEventsDict,
        overdueTasks: items?.filter((x) => !x.completed && x.dateStart && x.dateStart < Date.now()) || [],
        changeDifficulty,
    }
}

export interface SubtaskHelpers {
    toggleSubtaskCompleted: (id: string, index: number, checked: boolean) => Promise<any>
    removeSubtask: (id: string, index: number) => Promise<any>
    renameSubtask: (id: string, index: number, name: string) => Promise<any>
    addSubtask: (id: string, name: string) => Promise<any>
}
