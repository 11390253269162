import React from 'react'
import TalkingPanda from '../../components/TalkingPanda'
import { useHomeTutorial } from './useHomeTutorial'


const HomePanda = () => {
    const { tutorial, dialogue: dialog, handleNext } = useHomeTutorial()
    if (!tutorial) return <></>

    return (
        <TalkingPanda
            dialogue={dialog}
            text={dialog.text}
            defaultIsActive={tutorial.level === 0 || tutorial.createdAt > Date.now() - 1000 * 3}
            onNext={handleNext}
            options={dialog.responses || []} // don't filter for home
            next={dialog.next}
        />
    )
}

export default HomePanda