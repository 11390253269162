import { fetchGet } from 'src/lib/fetch'
import { Message } from 'src/types/mail'
import { useSWR } from '@/lib/swr'

export const useMail = () => {
    const { data, mutate } = useSWR<{ messages: Message[] }>('/api/message/vanishing')

    const setMessageAsRead = async (id: string) => {
        mutate(
            data && {
                ...data,
                messages: data.messages.slice(1),
            }
        )
        // mutate(data && {
        //     ...data,
        //     messages: data.messages.slice(1)
        // })
        await fetch('/api/message/vanishing', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
        })

        mutate(fetchGet('/api/message/vanishing'))
    }

    return {
        messages: data?.messages || [],
        setMessageAsRead,
    }
}
