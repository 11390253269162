'use client'
import React, { ReactNode } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { styled, keyframes, VariantProps } from '@stitches/react';
import { ChevronDownIcon } from '@radix-ui/react-icons';

export interface AccordionItem {
  content: ReactNode;
  title: string;
}

type AccordionProps = VariantProps<typeof AccordionRoot> & { style?: Record<string, any>, containerCSS?: Record<string, any> }

const Accordion = ({ items, containerCSS, ...props }: { items: AccordionItem[] } & AccordionProps) => {
  return (
    <AccordionRoot style={{ containerCSS }} collapsible type='single' {...props}>
      {
        items.map((task, i) => {
          return <AccordionItem key={i} value={i.toString()}>
            <AccordionTrigger>{task.title || 'No Name'}</AccordionTrigger>
            <AccordionContent>
              {task.content}
            </AccordionContent>
          </AccordionItem>
        })
      }
    </AccordionRoot>
  )
};

export default Accordion;

const AccordionRoot = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  borderColor: '$gray6',
  borderWidth: 1,
  borderStyle: 'solid',
  width: 300, // 300
  backgroundColor: '$gray6',
  boxShadow: `0 2px 5px colors.$overlay1`, // ${blackA.blackA2}
  marginBlock: 15,
  // borderRadius: 6,
  // width: 300,
  // /  backgroundColor: '$gray6',
  // boxShadow: `0 2px 10px $overlay4`, //var(--black-a4)
});

const AccordionTrigger = React.forwardRef<any, any>(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron aria-hidden />
    </StyledTrigger>
  </StyledHeader>
));

AccordionTrigger.displayName = 'AccordionTrigger';
const AccordionContent = React.forwardRef<any, any>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));
AccordionContent.displayName = 'AccordionContent';

const AccordionItem = styled(AccordionPrimitive.Item, {
  overflow: 'hidden',
  marginTop: 1,

  '&:first-child': {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  '&:last-child': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
    boxShadow: `0 0 0 2px colors.$gray12`,
  },
});


const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  padding: '0 20px',
  height: 45,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 15,
  lineHeight: 1,
  color: '$text',
  boxShadow: `0 1px 0 $gray6`,
  backgroundColor: '$loContrast',
  '&:hover': { backgroundColor: '$gray2', },
});

const StyledChevron = styled(ChevronDownIcon, {
  color: '$violet10',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
  fontSize: 15,
  color: '$gray11',
  backgroundColor: '$gray2',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled('div', {
  padding: '15px 20px',
  verticalAlign: 'middle',
  // display: 'inline-block',
});
