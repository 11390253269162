import TinyTasksDisplay from '@/components/specific/TinyTasksDisplay'
import React from 'react'
import { styled } from 'styled-system/jsx'

const Left = () => {
    return (
        <Container>
            <TinyTasksDisplay />
        </Container>
    )
}

export default Left

const Container = styled('div', {
    base: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
        // flex: 1,
    }
})