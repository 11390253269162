import { useStreak } from '@/hooks/useStreak'
import { styled } from 'styled-system/jsx'
import React from 'react'
import { FaFire } from "react-icons/fa";

const Streak = () => {
    const { streak } = useStreak()

    return (
        <Container>
            <Heading><FaFire /></Heading>
            {/* <Circle> */}
                {streak.current}
            {/* </Circle> */}
        </Container>
    )
}

export default Streak

const Container = styled('div', {
    base: {
        position: 'absolute',
        right: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5rem',
        gap: 10
    }
})

const Heading = styled('h5', {
    base: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '$gray9',
        margin: 0,
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center'
    }
})

// const Circle = styled('div', {