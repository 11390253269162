import { ChatBubbleIcon, DashboardIcon } from "@radix-ui/react-icons";
import Accordion from "./Accordion";
import { css } from 'styled-system/css';
import Link from "next/link";

const FAQ = () => {
    return (
        <Accordion items={[
            {
                title: 'How can I get notifications to my phone?',
                content: <>Sync your calendar with Google Calendar (<DashboardIcon className={css({ verticalAlign: 'middle' })} />).</>
            },
            {
                title: 'What should I do when I encounter a bug?',
                content: <>First, refresh the page. If the issue persists, add your bug in our
                    <Link target="_blank" href={'https://docs.google.com/document/d/1MlsGE_4pGFpBLt6lzYnNL6unIfn_H4imXdM669DRfJc/edit'}> bug report doc <ChatBubbleIcon className={css({ verticalAlign: 'middle' })} />,</Link> or contact me at christina@planda.ca.</>
            },
        ]}
        />
    )
};

export default FAQ;