'use client'
import React from 'react'
import { CalendarDatum } from '@nivo/calendar'
import dynamic from 'next/dynamic';

const Calendar = dynamic(() => import("@nivo/calendar").then(m => m.TimeRange), { ssr: false });
// type CalendarDatum = dynamic(() => import("@nivo/calendar").then(m => m.CalendarDatum), { ssr: false });
import { styled } from 'styled-system/jsx'
import { token } from 'styled-system/tokens';
// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/calendar

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

// NO responsive version so far of TimeRange

const RelativeBox = styled('div', {
    base: {
        position: "relative"
    }
})
const Title = styled('span', {
    base: {
        position: "absolute",
        marginTop: -10,
        left: 65,
        right: 0,
        marginInline: "auto",
        fontSize: "1rem"
    }
})
const HeatCalendar = ({ data, from, to, height = 180, width = 1100, title }: { data: CalendarDatum[] | undefined, to: string, from: string, height?: number, width?: number, title?: string }) => {
    return (
        <RelativeBox>
            {title && <Title>{title}</Title>}
            <Calendar
                theme={{
                    text: {

                        fill: token('colors.$gray11'),
                    },
                    tooltip: {
                        container: {
                            background: token('colors.$loContrast'),
                        },
                    },
                }}
                height={height}
                width={width}
                data={data || []}
                from={from}
                to={to}
                emptyColor={token('colors.$gray4')}
                colors={['#9be9a8', '#40c463', '#30a14e', '#216e39']}
                // colors={['var(--colors-primary5)', 'var(--colors-primary6)', 'var(--colors-primary8)', 'var(--colors-primary10)']}
                margin={{ top: 40, right: 40, bottom: 10, left: 30 }}
                // monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor={token('colors.$primary1')}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'row',
                        translateY: 36,
                        itemCount: 4,
                        itemWidth: 42,
                        itemHeight: 36,
                        itemsSpacing: 14,
                        itemDirection: 'right-to-left',
                    }
                ]}
            />
        </RelativeBox>
    )
}

export default HeatCalendar