import React, { useCallback, useRef, useState } from 'react'
import CheckList from '@/components/common/CheckList'
import { css } from 'styled-system/css';
import { CheckCircledIcon, Pencil1Icon } from '@radix-ui/react-icons'
import { useOutsideAlerter } from '@/hooks/useOutsideAlerter'
import { styled } from 'styled-system/jsx'
import { debounce } from 'lodash'
import useSWR from 'swr'
import ProgressBar from './ProgressBar'
import { fetchGet, fetcher } from 'src/lib/fetch'
export const IconBar = styled('span', {
    base: {
        display: "flex",
        marginRight: "1rem",
        right: 0,
        position: "absolute"
    }
})

function edit(para: string, mutate: any) {
    const list = storageToList(para)
    const storage = listToStorage(list)
    fetcher(`/api/quick/tasks`, 'PUT', { storage })
    mutate({ storage })
}

const TinyTasksDisplay = ({ }: {
}) => {
    // every newline is a new item
    // use a textarea to edit
    const { data, mutate } = useSWR<{ storage: string }>(`/api/quick/tasks`, fetchGet, { revalidateOnFocus: false })

    const outsideAlertRef = useRef(null);
    const outsideAlertOmitRef = useRef(null);
    useOutsideAlerter(outsideAlertRef, () => setIsEditing(false), [outsideAlertOmitRef]);

    const { storage } = data || {}
    const list = storageToList(storage || '')


    const debouncedEdit = useCallback(debounce((para: string) => edit(para, mutate), 250), [mutate])

    function setChecked(index: number, checked: boolean) {
        const storage = listToStorage(list.map((item, i) => {
            if (i === index) {
                return { ...item, checked }
            }
            return item
        }))

        // console.log('storage', storage)
        // BUG HERE
        fetcher(`/api/quick/tasks`, 'PUT', { storage })

        mutate({ storage })
    }


    const [isEditing, setIsEditing] = useState(false)

    const progress = list.length === 0 ? null : list.filter(({ checked }) => checked).length / list.length

    return (
        <Container >
            {list.length === 0 && !isEditing ? <Placeholder>
                Click the pencil to tiny tasks&nbsp;<EditIcon ref={outsideAlertOmitRef}
                    onClick={() => setIsEditing(p => !p)}
                />
            </Placeholder> : <IconBar>
                <EditIcon ref={outsideAlertOmitRef}
                    onClick={() => setIsEditing(p => !p)}
                />
            </IconBar>}
            {
                isEditing ?
                    <TextAreaHolder>
                        <TextArea placeholder='Type your tiny tasks in here.' ref={outsideAlertRef} defaultValue={storage}
                            onChange={(e) => debouncedEdit(e.target.value)}
                        />
                        <GoodIcon />
                    </TextAreaHolder>
                    : <CheckList
                        className={css({ maxHeight: 340, minHeight: 0, height: '100%', overflow: 'scroll', paddingBottom: 60 })}
                        items={list.map(({ text, checked }) => ({ html: <div>{text}</div>, checked }))}
                        handleChecked={async (index, checked) => {
                            // const newList = list.map((item, i) => i === index ? { ...item, checked } : item)
                            setChecked(index, checked)
                        }}
                    />
            }
            <End>
                {progress !== null && list.length >= 3 && <ProgressBar progress={progress * 100} />}
            </End>
        </Container>
    )
}

export default TinyTasksDisplay

function storageToList(para: string) {
    return para.split("\n").filter(Boolean).map((item) => {
        const numStr = item.trim().slice(0, 2).trim()
        let text = item.trim().slice(2).trim()
        let num = numStr === '1.' ? 1 : numStr === '0.' ? 0 : null
        if (num === null) {
            num = 0
            text = item.trim()
        }
        return { checked: num === 1, text }
    })
}

function listToStorage(list: { checked: boolean, text: string }[]) {
    return list.map(({ checked, text }) => `${checked ? '1.' : '0.'} ${text}`).join("\n")
}

const Container = styled('div', {
    base: {
        boxSizing: "border-box",
        position: "relative",
        paddingInline: "1.5rem",
        paddingBlock: "1rem",
        flex: 1,
        overflowY: "auto",
        fontSize: "0.75rem",
        maxWidth: 270,
        height: "100%",
        background: "$primary2",
        borderRadius: 15
    }
})

const TextAreaHolder = styled('div', {
    base: {
        position: "relative",
        width: "100%",
        height: "100%"
    }
})

const GoodIcon = styled(CheckCircledIcon, {
    base: {
        position: "absolute",
        bottom: "0.5rem",
        right: "0.5rem",
        "&:hover": {
            color: "$green7"
        }
    }
})

const TextArea = styled('textarea', {
    base: {
        border: "1px solid",
        width: "100%",
        height: "100%",
        resize: "none",
        padding: "0.5rem"
    }
})

const Placeholder = styled('div', {
    base: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "$gray7",
        margin: "auto"
    }
})

const EditIcon = styled(Pencil1Icon, {
    base: {
        height: "1.25rem",
        width: "1.25rem",
        zIndex: 50,
        color: "$gray7",
        "&:hover": {
            color: "$green7",
            scale: 1.1
        }
    }
})

const End = styled('section', {
    base: {
        display: "flex",
        height: "max-content",
        flexDirection: "column",
        justifyContent: "end",
        marginTop: "auto",
        bottom: 0,
        position: "absolute",
        width: "80%"
    }
})
