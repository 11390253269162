// To help you learn to use Planda without feeling overwhelmed, we have put you in Planda Simplified.

// Planda Simplified is Planda cut down to the bare minimum needed to effectively plan with Planda.

// We’ll gradually reveal new features as you get accustomed to using Planda.

// You can exit this mode anytime in your settings. (show arrow)
import { setTemporaryState } from '@/redux/features/temporaryStateSlice'
import { AppDispatch } from '@/redux/store'
import { Dispatch, SetStateAction } from 'react'
import { Dialogues, Key, Tutorial } from 'src/features/easter-eggs/types'

export const LEVEL1_DIALOGUES: Dialogues = {
    '0': {
        text: "Welcome to the homepage! I'm Plandy.",
        next: '1',
    },
    '1': {
        text: "To help you learn to use Planda without feeling overwhelmed, I've have put you in Planda Simplified.",
        next: '2',
    },
    '2': {
        text: 'Planda Simplified is cut down to the bare minimum needed to effectively plan with Planda.',
        next: '3',
    },
    '3': {
        text: "We'll gradually reveal new features as you get accustomed to using Planda.",
        next: 'settings',
    },
    settings: {
        text: 'You can exit this mode anytime in your settings.',
        next: 'show',
    },
    show: {
        text: "I'd love to show you around. Where shall we start our paw-some journey?",
        responses: [
            {
                next: 'list',
                reply: 'Courses/todo list (suggested)',
            },
            {
                next: 'calendar-popups',
                reply: 'Calendars',
            },
            {
                next: 'self-explore',
                reply: "I'll explore myself",
            },
            {
                next: 'veteran',
                reply: "I've used Planda before",
            },
        ],
    },
    'self-explore': {
        text: "Sounds good! I'll be standing in this corner if you ever change your mind.",
    },
    'calendar-popups': {
        text: 'Click any of these buttons (top-left) to make your calendars pop-up.\nEach calendar can be resized to show more.',
        next: 'show',
    },
    list: {
        text: 'Click on one of these to navigate to your courses.',
        next: 'show',
    },
    veteran: {
        text: 'If you ever need motivation, click on me for some inspirational quotes. See you around!',
        next: 'end',
    },
    congrats: {
        text: 'Great job navigating through Planda! If you ever need motivation, click on me for some inspirational quotes. See you around!',
        next: 'end',
    },
    '': {
        text: 'How on earth did you get here?',
    },
    end: {
        text: "If you are seeing this, you've found a bug. Please let me know how you got here. Thanks!",
    },
}

export const level1HandleNext = (
    val: Key,
    setDialogKey: Dispatch<SetStateAction<string>>,
    edit: (updates: Partial<Tutorial>) => void,
    options: {
        dispatch: AppDispatch
        routerPush: (x: string) => void
    }
) => {
    const { dispatch, routerPush } = options
    if (!val) {
        setDialogKey('0')
        return
    }
    val = val.toString()

    if (val === 'show') {
        // edit tutorial to current = 'show'
        edit({ current: val })
    }

    if (['calendar-popups', 'list', 'settings'].some((v) => v === val)) {
        // should make arrows appear
        dispatch(setTemporaryState({ tutorialArrow: val }))
        routerPush(`/home`)
    }
    setDialogKey(val)
}
